import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';

import { T } from '@/helpers/i18n';
import { formatMoney } from '@/helpers/moneyFormatter/moneyFormatter';

import './item.scss';
import React from 'react';

type Props = {
  name: string;
  charges: Charge[];
  locale: string;
  currency: string;
} & T;

type Charge = {
  name: string;
  value: number;
}

const Charges = ({ name, charges = [], locale, currency }: Props) => {
  return (
    <div id={`charges-${name}`}>
      <Text>{name + ':'}</Text>
      {charges.map((charge: Charge, key) => (
        <Text key={key} style={{ wordBreak: 'break-word' }}>
          &nbsp;&nbsp;{'- ' + charge.name + ' : ' + formatMoney(locale, currency, charge.value)}
        </Text>
      ))}
    </div>
  );
};

export default withTranslation('translation')(Charges);
