import { withTranslation } from 'react-i18next';
import { Text } from '@ticketmaster/aurora';

import { T } from '@/helpers/i18n';
import { formatMoney } from '@/helpers/moneyFormatter/moneyFormatter';

import './item.scss';
import React from 'react';

type Props = {
  name: string;
  value: number;
  locale: string;
  currency: string;
} & T;

const Charge = ({ name, value, locale, currency }: Props) => {
  return (
    <div id={`charge-${name}`}>
      <Text>{`${name}: ${formatMoney(locale, currency, value)}`}</Text>
    </div>
  );
};

export default withTranslation('translation')(Charge);
