import api from '@/helpers/api';
import { ERROR_MSGS } from '../wallet.constants';
import i18next from '@/helpers/i18n';
import walletBannerOpts from '../wallet.banners';

export async function getEnvelope(token: string) {
  try {
    const mainHash = await fetchMainHash();
    const headers = {
      ...api.defaults.headers.get,
      "X-Ui-Version": mainHash,
    };

    return await api.get(`/envelopes/${token}/token`, { params: { language: i18next.language }, headers: headers });
  } catch (err: any) {
    const { status = 500, data: { message = '', tmOrdersUrl } } = err?.response || { data: {} };
    const validate = { status: 'defaultError', tmOrdersUrl, message: 'defaultErrorMessage' };
    if (status === 401 || status === 403) {
      validate.status = 'forbidden';
    }
    if (status === 501) {
      validate.status = 'redemptionError';
      validate.message = err?.response?.data?.message;
    }
    if (status === 422) {
      validate.status = 'notReady';
    }
    if (status === 500) {
      validate.status = 'defaultError';
    }
    if (message.indexOf(ERROR_MSGS.REFUNDED) !== -1) {
      validate.status = 'noTickets';
    }
    if (message === ERROR_MSGS.ALREADY_REDEEMED) {
      validate.status = 'alreadyRedeemed';
    }
    err.validate = validate;
    throw err;
  }
}

export async function redeemTickets(activeToken: string) {
  let language = i18next.language;
  try {
    if (!language) {
      language = 'en-US';
    }
    await redeem(activeToken, language);
  } catch (e) {
    return walletBannerOpts.redeemError();
  }
}

async function redeem(activeToken: string, language: string) {
  const url = `/envelopes/redeem/${activeToken}?language=${language}`;
  const mainHash = await fetchMainHash();
  const headers = {
    ...api.defaults.headers.put,
    "X-UI-Version": mainHash,
  };

  const result = await api.put(url, { headers: headers });
  let uri = result.data.uri;

  if (uri.match('^http://')) {
    uri = uri.replace('http://', 'https://');
  }
  window.location.href = uri;
}

async function fetchMainHash() {
  try {
    const manifestResponse = await fetch('/asset-manifest.json');
    const manifestJson = await manifestResponse.json();
    return manifestJson.files['main.js'].match(/main\.([a-z0-9]+)\.js/)?.[1] || 'local';
  } catch {
    return 'unknown';
  }
}
